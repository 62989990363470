<template>
  <div class="Login overflow-hidden">
    <div class="MaskGroup absolute left-0 top-0">
      <img
        :src="group239"
        style="width: 732.5px; height: 703px; object-fit: cover; object-position: 0 100%"
      />
    </div>

    <div
      class="Group84 absolute bg-white shadow border rounded-xl md:mx-0 px-8 -translate-x-1/2 transform-gpu left-p50 xs:mx-auto top-p50 -translate-y-1/2 w-full z-10"
      style="max-width: 573px; height: 506px"
    >
      <div class="Ellipse38 w-32 h-32 absolute rounded-full right-38 top-14 z-0 bg-hwarning"></div>
      <div class="Ellipse39 w-10 h-10 absolute rounded-full right-72 top-41 z-0 bg-hwarning"></div>

      <div class="inline-flex flex-col space-y-0.5 items-center justify-end w-full h-16 mt-2.2">
        <div class="text-sm font-bold text-cyan-700 text-center z-10">
          Fitur ini hanya diperuntukan untuk staf, Silahkan login sebagai staf, login jika anda
          sebagai staf kami.
        </div>
      </div>
      <div class="inline-flex flex-col space-y-0.5 items-center justify-end w-full h-16 mt-6">
        <div class="text-4xl font-bold text-cyan-700 text-center z-10">Login</div>
        <div class="text-3xl font-normal text-cyan-700 z-10">Employee</div>
      </div>

      <div class="flex flex-col">
        <div class="relative mt-8 z-10">
          <input
            class="appearance-none border-none shadow-md pl-12 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-xl w-full py-2 px-3 h-11.5 text-gray-800 leading-tight focus:ring-transparent text-sm"
            id="username"
            type="text"
            v-model="form.username"
            placeholder="Your email"
          />
          <div class="absolute left-0 inset-y-0 flex items-center">
            <icon-user class="h-7 w-7 ml-3 text-gray-500 hover:text-gray-800 p-0.5" />
          </div>
        </div>

        <div class="relative mt-7 z-10">
          <input
            class="text-sm appearance-none border-none shadow-md pl-12 focus:placeholder-gray-800 placeholder-gray-500 transition rounded-xl w-full py-2 px-3 h-11.5 text-gray-800 leading-tight focus:ring-transparent"
            id="pass"
            type="password"
            v-model="form.password"
            placeholder="Password....."
          />
          <div class="absolute left-0 inset-y-0 flex items-center">
            <icon-password class="h-7 w-7 ml-3 text-gray-500 hover:text-gray-800 p-0.5" />
          </div>
        </div>
      </div>

      <div class="Group8 h-12 mt-7">
        <button
          class="btn rounded-xl border-0 w-full text-white bg-hblue1"
          @click="toLogin"
          :class="loading == true ? 'loading' : ''"
          :disabled="loading"
        >
          <span class="text-sm capitalize">Login</span>
        </button>
      </div>
      <p
        class="text-2xl font-medium text-center mt-9 cursor-pointer"
        @click="$router.push('/login')"
      >
        Cancel
      </p>
    </div>

    <div class="Ellipse27 absolute right-0 bottom-0">
      <img :src="ellipse27" />
    </div>
  </div>
</template>

<script>
import bgDecor from '@/assets/images/bg_decor.png'
import group239 from '@/assets/images/group239.png'
import gpIcon from '@/assets/images/google_play.png'
import icareLogo from '@/assets/images/i_care_logo_large.png'
import ellipse27 from '@/assets/images/ellipse27.png'

import IconUser from '@/assets/icons/icon-userOutline.svg?inline'
import IconPassword from '@/assets/icons/icon-passwordOutline.svg?inline'

import { mapActions } from 'vuex'

export default {
  name: 'signinStaf-page',
  components: {
    IconUser,
    IconPassword
  },
  data() {
    return {
      bgDecor,
      group239,
      gpIcon,
      icareLogo,
      ellipse27,
      loading: false,
      form: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('auth', ['LOGIN']),
    async toLogin() {
      this.loading = true
      if (!this.form.username || !this.form.password) this.loading = false
      else {
        let resLog = await this.LOGIN(this.form)

        if (resLog) {
          setTimeout(() => {
            this.$router.push({ name: 'register' })
            this.loading = false
          }, 500)
        }
      }

      this.loading = false
    }
  }
}
</script>
